
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import convertPhoneToFull from '@/utils/convertPhoneToFull'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input.vue'
import vButton from '@/components/v-button.vue'
import { ISaveReqPayload } from '@/types/requisites'
export default defineComponent ({
  name: 'VSectionReq',
  components: {
    Form,
    vInput,
    vButton,
  },

  data() {
    return {
      name_organization: '',
      inn: '',
      phone: '',
    }
  },

  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('requisites', ['REQUISITES']),
  },

  async mounted() {
    await this.FETCH_REQUISITES(this.GET_AUTHENTIFICATED)
    await this.fetchData()
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },

  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('requisites', ['FETCH_REQUISITES', 'SAVE_REQUISITES']),
    fetchData(): void {
      this.name_organization = this.REQUISITES.name_organization
        ? this.REQUISITES.name_organization
        : ''
      this.inn = this.REQUISITES.inn ? this.REQUISITES.inn : ''
      this.phone = this.REQUISITES.phone ? this.filterPhone(this.REQUISITES.phone) : ''
    },
    filterPhone(phone: string): string {
      if (phone) {
        let ph = phone.replace(/\s/g, '')
        ph = ph.slice(1)
        return convertPhoneToFull(ph)
      }
      return ''
    },
    async checkForm(): Promise<void> {
      this.TOGGLE_PRELOADER(true)
      const req: ISaveReqPayload = {

        token: this.GET_AUTHENTIFICATED,
        data: {
          name_organization: this.name_organization,
          inn: this.inn,
          phone: convertPhoneFormat(this.phone)
        },
      }
      await this.SAVE_REQUISITES(req)
      this.TOGGLE_PRELOADER(false)
    },
  },
})
